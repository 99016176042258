import { createBrowserRouter } from 'react-router-dom'
import { MenuScreen } from './menu/MenuScreen'
import React from 'react'
import { DeliveryList } from './delivery-list/DeliveryList'
import { MenuEditor } from './edit-menu/MenuEditor'
import { PayRedirect } from './pay-redirect/PayRedirect'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <MenuScreen/>
  },
  {
    path: '/redirect',
    element: <MenuScreen/>
  },
  {
    path: '/deliveries',
    element: <DeliveryList/>
  },
  {
    path: '/editor',
    element: <MenuEditor/>
  },
  {
    path: '/pay',
    element: <PayRedirect/>
  },
  {
    path: '/menu',
    element: <MenuScreen/>
  }
])
