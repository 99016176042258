import { apiCall, CallType } from '../shared/utils/api'
import { type Delivery, type PayDelivery, type UserDelivery } from '../shared/model'
import { getTenantId } from '../shared/tenant/tenant'

const getUserDelivery = async (date: string, menuName: string): Promise<UserDelivery> => {
  return await apiCall(true, `/orders/${date}/${menuName}?tenant=${getTenantId()}`, CallType.GET) ?? []
}
const removeDeliveryOrder = async (deliveryId: number, orderId: number): Promise<void> => {
  return await apiCall(true, `/deliveries/order/${deliveryId}/${orderId}`, CallType.DELETE)
}

const getDelivery = async (date: string, menuName: string): Promise<Delivery[]> => {
  return await apiCall(true, `/deliveries/date/${date}/${menuName}?tenant=${getTenantId()}`, CallType.GET) ?? []
}

const payDelivery = async (delivery: PayDelivery): Promise<void> => {
  return await apiCall(true, '/deliveries/pay', CallType.POST, delivery)
}

const getAllDeliveries = async (): Promise<Delivery[]> => {
  return await apiCall(true, `/deliveries/all?tenant=${getTenantId()}`, CallType.GET) ?? []
}

const getAllDeliveriesDates = async (menuName: string): Promise<string[]> => {
  return await apiCall(true, `/deliveries/all/${menuName}/dates?tenant=${getTenantId()}`, CallType.GET) ?? []
}

const payUserOrders = async (deliveryId: number, userOId: string): Promise<string> => {
  return await apiCall(false, `/deliveries/pay/redirect?deliveryId=${deliveryId}&userOId=${userOId}`, CallType.GET) ?? ''
}

export const deliveryService = {
  getUserDelivery,
  removeDeliveryOrder,
  getDelivery,
  payDelivery,
  getAllDeliveries,
  getAllDeliveriesDates,
  payUserOrders
}
