import { asGlobalStore } from '../utils/stores'
import { msalInstance } from '../../index'
import { isAfter, toDate } from 'date-fns'

const createStore = () => ({
  async performAuthCheck () {
    if (!msalInstance.getActiveAccount()) {
      await msalInstance.loginRedirect()
    }
  },
  async getToken () {
    const expiryDate = window.sessionStorage.getItem('msal.expiration')
    if (expiryDate && isAfter(toDate(expiryDate), new Date())) {
      return window.sessionStorage.getItem('msal.bearer') ?? ''
    }
    const request = {
      scopes: [],
      roles: ['USER.ORDER']
    }
    await msalInstance.acquireTokenSilent(request).then(tokenResponse => {
      window.sessionStorage.setItem('msal.expiration', tokenResponse.expiresOn?.toString() ?? '')
      window.sessionStorage.setItem('msal.bearer', tokenResponse.idToken)
    }).catch(e => {
      console.log(e)
      AuthStore.login()
    })
    return window.sessionStorage.getItem('msal.bearer') ?? ''
  },
  async login () {
    await msalInstance.loginRedirect()
  },
  async logout () {
    await msalInstance.logoutRedirect()
  }
})
export const AuthStore = asGlobalStore(createStore())
