import { type Delivery, type Order, type PayDelivery } from '../../../../shared/model'
import {
  Badge,
  Button, Flex, FormControl, FormLabel, Heading, HStack, Input, InputGroup, InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay, Stack, Switch,
  Table,
  TableContainer,
  Tbody,
  Td, Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useColorModeValue, VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { getChosenOptionsAsString } from '../../../../shared/utils/chosen-options'
import { proxyToJs } from '../../../../shared/utils/stores'
import { getSumPriceFromDelivery, getTotalAmountFromDelivery } from '../../../../shared/utils/delivery'
import { Field, Form, Formik } from 'formik'

interface Props {
  modalOpen: boolean
  closeModal: () => void
  orders: Delivery
  payDelivery: (delivery: PayDelivery) => Promise<void>
}

export const OverviewModal = (props: Props) => {
  const { orders, modalOpen, closeModal, payDelivery } = props
  const [isFree, setIsFree] = useState(false)
  const [variableOrders, setVariableOrders] = useState<Order[]>([])
  const [sumPrice, setSumPrice] = useState(orders.sumPriceOrders)
  const bg = useColorModeValue('white', 'utils.bg.dark')
  const bgCardHeader = useColorModeValue('utils.40', 'utils.dark.elevated.06dp')

  useEffect(() => {
    setSumPrice(orders.sumPriceOrders)
  }, [orders.sumPriceOrders])

  const groupAndSumOrders = (orders: Order[]): Order[] => {
    const groupedOrders: Record<string, Order> = {}
    orders.forEach((order) => {
      const key = order.comment ? `${order.menuItem.name}_${order.comment}_${getChosenOptionsAsString(order.chosenOptions)}` : `${order.menuItem.name}_${getChosenOptionsAsString(order.chosenOptions)}`
      if (groupedOrders[key]) {
        let amount = Number(groupedOrders[key].amount)
        let users = groupedOrders[key].user
        amount += Number(order.amount)
        groupedOrders[key].amount = amount
        users = `${users}, ${order.user}`
        groupedOrders[key].user = users
      } else {
        groupedOrders[key] = { ...order }
      }
    })
    return Object.values(groupedOrders)
  }

  const getAllVariablePricedItems = (orders: Order[]) => {
    const variablePricedItems: Order[] = []
    orders.forEach(order => {
      if (order.menuItem.variablePrice && !variablePricedItems.find(x => x.menuItem.id === order.menuItem.id)) {
        variablePricedItems.push(order)
      }
    })
    return variablePricedItems
  }

  useEffect(() => {
    setVariableOrders(getAllVariablePricedItems(orders.orders))
  }, [orders])

  const updatedSumPrice = () => {
    let sumPrice = 0
    orders.orders.forEach(x => {
      if (!variableOrders.find(y => y.menuItem.id === x.menuItem.id)) {
        sumPrice += x.sumPrice
      } else {
        const variableOrder = variableOrders.find(y => y.menuItem.id === x.menuItem.id)
        sumPrice += (variableOrder?.amount ?? 0) * (variableOrder?.menuItem.price ?? 0)
      }
    })
    return sumPrice
  }

  const updateVariableOrders = (id: number, price: number) => {
    const tempVariableOrders = [...variableOrders]
    const index = tempVariableOrders.findIndex(x => x.menuItem.id === id)
    if (index !== -1) {
      tempVariableOrders[index].menuItem.price = price
      setVariableOrders(proxyToJs(tempVariableOrders))
    }
    setSumPrice(updatedSumPrice())
  }

  return (
        <Modal isOpen={modalOpen} onClose={() => {
          setIsFree(false)
          closeModal()
        }}>
            <ModalOverlay/>
            <ModalContent bg={bg} minW={'50%'} minH={'50%'} borderRadius={'lg'}>
                <ModalHeader padding={4} bg={bgCardHeader}>
                    <HStack>
                        <Text>
                            Bestelling overzicht
                        </Text>
                        {orders.usernamePayer !== ''
                          ? <Badge colorScheme='green'>betaald</Badge>
                          : <Badge>nog betalen</Badge>
                        }
                    </HStack>
                </ModalHeader>
                <ModalCloseButton/>
                <ModalBody backgroundColor={bg} padding={4} rowGap={4}>
                    <TableContainer>
                        <Table variant='simple'>
                            <Thead>
                                <Tr>
                                    <Th>Aantal</Th>
                                    <Th>Product</Th>
                                    <Th>Opties</Th>
                                    <Th>Opmerking</Th>
                                    <Th>Gebruiker</Th>
                                    {orders.usernamePayer !== '' && <Th>Betaald</Th>}
                                </Tr>
                            </Thead>
                            {orders.usernamePayer === ''
                              ? <Tbody>
                                    {groupAndSumOrders(orders.orders).map((o, index) => (
                                        <Tr key={index}>
                                            <Td>{o.amount}</Td>
                                            <Td>{o.menuItem.name}</Td>
                                            <Td>{getChosenOptionsAsString(o.chosenOptions)}</Td>
                                            <Td>{o.comment}</Td>
                                            <Td>{o.user}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                              : <Tbody>
                                    {orders.orders.map((o, index) => (
                                        <Tr key={index}>
                                            <Td>{o.amount}</Td>
                                            <Td>{o.menuItem.name}</Td>
                                            <Td>{getChosenOptionsAsString(o.chosenOptions)}</Td>
                                            <Td>{o.comment}</Td>
                                            <Td>{o.user}</Td>
                                            <Td>{o.paid ? <Badge colorScheme='green'>Betaald</Badge> : <Badge>nog betalen</Badge>}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            }
                            <Tfoot>
                                <Tr>
                                    <Th>Totaal</Th>
                                    <Th></Th>
                                    <Th></Th>
                                    <Th></Th>
                                    {orders.usernamePayer !== '' && <Th></Th>}
                                    <Th><Text fontWeight={700} fontSize={16}>{getTotalAmountFromDelivery(orders)}</Text></Th>
                                </Tr>
                            </Tfoot>
                        </Table>
                    </TableContainer>
                    {variableOrders.length > 0 && orders.usernamePayer === '' &&
                        <>
                    <Heading as={'h2'} my={4} fontSize={24}>
                        Variabele prijzen
                    </Heading>
                            <Stack spacing={4}>
                                {variableOrders.map(order => (
                                    <Stack key={order.id}>
                                    <FormLabel my={0}>{order.menuItem.name}</FormLabel>
                                        <InputGroup w={'6rem'}>
                                            <InputLeftElement>
                                                <Text color={'utils.gray'}>{'€'}</Text>
                                            </InputLeftElement>
                                            <Input type={'number'} defaultValue={order.menuItem.price} onChange={(e) => { updateVariableOrders(order.menuItem.id, Number(e.target.value)) }} />
                                        </InputGroup>
                                    </Stack>
                                ))}
                            </Stack>
                        </>
                    }
                </ModalBody>
                {orders.usernamePayer === ''
                  ? <ModalFooter bg={bgCardHeader}>
                    <Formik
                        initialValues={{
                          paymentURL: ''
                        }}
                        onSubmit={async (data, { setSubmitting }) => {
                          setSubmitting(true)
                          data.paymentURL !== ''
                            ? await payDelivery({
                              ...data,
                              variablePriceOrders: variableOrders,
                              deliveryId: orders.id
                            })
                            : await payDelivery({ deliveryId: orders.id })
                          setSubmitting(false)
                          closeModal()
                        }}
                    >
                        {({ isSubmitting, setFieldValue, handleSubmit, values, errors }) => (
                            <Form
                                style={{ width: '100%' }}
                                onSubmit={handleSubmit}
                            >
                                <VStack w={'inherit'}>
                                    <HStack>
                                    </HStack>
                                    <Flex w={'inherit'} justifyContent={'space-between'}>

                                        <HStack>
                                            <FormLabel my={0}>Gratis</FormLabel>
                                            <Field
                                                as={Switch}
                                                id={'free'}
                                                name={'free'}
                                                onChange={(e: any) => {
                                                  setIsFree(e.target.checked)
                                                  if (e.target.checked) {
                                                    setFieldValue('paymentURL', '')
                                                  }
                                                }}
                                            />
                                            <FormControl isRequired={true}>
                                                <Field
                                                    as={Input}
                                                    isDisabled={isFree}
                                                    variant={'utilsInput'}
                                                    placeholder={'Betaal-URL'}
                                                    id={'paymentURL'}
                                                    name={'paymentURL'}
                                                    ml={4}
                                                />
                                            </FormControl>

                                        </HStack>
                                        <HStack>

                                               <Text
                                                fontWeight={500}>{`Totaal: ${sumPrice ? `€${sumPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : getSumPriceFromDelivery(orders)} `}</Text>
                                            <Button type={'submit'} isDisabled={orders.usernamePayer !== ''}
                                                    variant={'utilsButtonPrimary'} isLoading={isSubmitting}>Betalen</Button>
                                        </HStack>
                                    </Flex>
                                    </VStack>
                            </Form>
                        )}
                    </Formik>
                </ModalFooter>
                  : <ModalFooter bg={bgCardHeader}>
                        <Text
                            fontWeight={500}>{`Totaal: ${sumPrice ? `€${sumPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : getSumPriceFromDelivery(orders)} `}</Text>
                    </ModalFooter>
                }
            </ModalContent>
        </Modal>
  )
}
