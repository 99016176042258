import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import { Center, Spinner, Text, VStack } from '@chakra-ui/react'
import { deliveryService } from '../services/delivery-service'

export const PayRedirect = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const deliveryId = queryParams.get('deliveryId')
  const userOId = queryParams.get('userOId')

  const payUserOrders = async (deliveryId: number, userOId: string) => {
    const paymentUrl = await deliveryService.payUserOrders(deliveryId, userOId)
    if (paymentUrl) {
      const wrappedPaymentUrl = paymentUrl.startsWith('https://') ? paymentUrl : `https://${paymentUrl}`
      window.location.replace(wrappedPaymentUrl)
    }
  }

  useEffect(() => {
    if (deliveryId && userOId) {
      payUserOrders(parseInt(deliveryId), userOId)
    }
  }, [])

  return (
      <Center h={'100vh'}>
        <VStack>
          <Spinner/>
          <Text fontSize={16} fontWeight={500}>
            Een moment geduld aub...
          </Text>
          <Text>
            U wordt over enkele ogenblikken doorgestuurd naar de betaalpagina.
          </Text>
        </VStack>
      </Center>
  )
}
