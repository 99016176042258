import { type Delivery } from '../model'

export const getTotalAmountFromDelivery = (order?: Delivery) => {
  if (!order) {
    return 0
  }
  let total = 0
  order.orders.forEach(x => { total += x.amount })
  return total
}

export const getSumPriceFromDelivery = (order?: Delivery) => {
  let sumPrice = 0
  if (order) {
    if (order.sumPriceOrders) {
      sumPrice = order.sumPriceOrders
    }
    if (!order.sumPriceOrders) {
      order.orders.forEach(x => {
        sumPrice += x.sumPrice
      })
    }
  }
  // return sumPrice
  return `€${sumPrice.toLocaleString(undefined, { minimumFractionDigits: 2 })}`
}

export const getFirstOrNonPayedDelivery = (deliveries: Delivery[]) => {
  let latestDelivery: Delivery | undefined
  for (const x of deliveries) {
    latestDelivery = x
    if (x.usernamePayer === '') {
      return x
    }
  }
  return latestDelivery
}

export const getFirstNonPayedDelivery = (deliveries: Delivery[]) => {
  for (const x of deliveries) {
    if (x.usernamePayer === '') {
      return x
    }
  }
  return undefined
}

export const hasOpenDelivery = (deliveries: Delivery[]) => {
  for (const x of deliveries) {
    if (x.usernamePayer === '') {
      return true
    }
  }
  return false
}
