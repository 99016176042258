import { orderService } from '../../services/order-service'
import { type Delivery, type Order, type PayDelivery, type UserDelivery, type UserOrder } from '../model'
import { formatBackEndDate } from '../utils/date'
import { MenuStore } from './menu-store'
import { deliveryService } from '../../services/delivery-service'
import { msalInstance } from '../../index'
import { menusService } from '../../services/menu-service'
import { asGlobalStore, proxyToJs } from '../utils/stores'
import { getFirstNonPayedDelivery, hasOpenDelivery } from '../utils/delivery'

const createStore = () => ({
  userDelivery: {} as UserDelivery,
  dayDelivery: [] as Delivery[],
  deliveriesDates: [] as string[],
  menuName: '',
  isLoading: false,
  deliveryDate: new Date(),
  async setPersonalOrder (order: UserOrder) {
    await orderService.postOrder(order)
    await this.getUserDelivery(MenuStore.getSelectedMenuName())
    // await this.refreshDelivery(MenuStore.getSelectedMenuName())
  },
  setUserDelivery (userDelivery: UserDelivery) {
    this.userDelivery = userDelivery
  },
  setDayDelivery (dayDelivery: Delivery[]) {
    this.dayDelivery = dayDelivery
  },
  setLoading (loading: boolean) {
    this.isLoading = loading
  },
  getDeliveryDate () {
    return this.deliveryDate
  },
  async refresh () {
    await MenuStore.onInitialize()
  },
  async setDeliveryDate (deliveryDate: Date) {
    this.deliveryDate = deliveryDate
    await this.refreshDelivery(MenuStore.getSelectedMenuName(), true)
  },
  async removePersonalOrder (deliveryId: number, orderId: number) {
    const updatedUserDelivery = this.userDelivery
    const indexUserDelivery = updatedUserDelivery.orders.findIndex(x => x.id === orderId)
    if (indexUserDelivery !== -1) {
      updatedUserDelivery.orders.splice(indexUserDelivery, 1)
      this.setUserDelivery(updatedUserDelivery)
    }
    await deliveryService.removeDeliveryOrder(deliveryId, orderId)
  },
  async getUserDelivery (menuName: string) {
    const userDelivery = await deliveryService.getUserDelivery(formatBackEndDate(this.getDeliveryDate()), menuName)
    this.setUserDelivery(userDelivery)
  },
  async deleteFirstNonPayedDelivery () {
    const currentDelivery = getFirstNonPayedDelivery(this.dayDelivery)
    if (currentDelivery) {
      const index = this.dayDelivery.findIndex(x => x.id === currentDelivery.id)
      if (index !== -1) {
        this.dayDelivery.splice(index, 1)
      }
    }
  },
  async updateDelivery (delivery: Delivery) {
    const tempDayDelivery = [...this.dayDelivery]
    const currentDelivery = tempDayDelivery.find(x => x.id === delivery.id)
    if (delivery.usernamePayer !== '') {
      await this.getUserDelivery(MenuStore.getSelectedMenuName())
    }
    if (currentDelivery) {
      currentDelivery.orders = currentDelivery.orders.filter(x => delivery.orders.some(o => o.id === x.id))
      currentDelivery.orders.push(...delivery.orders.filter(x => !currentDelivery.orders.some(o => o.id === x.id)).map(x => ({ ...x, new: true })))
      if (delivery.usernamePayer !== '') {
        currentDelivery.orders = delivery.orders
      }
      currentDelivery.sumPriceOrders = delivery.sumPriceOrders
      currentDelivery.usernamePayer = delivery.usernamePayer
      const index = tempDayDelivery.findIndex(x => x.id === currentDelivery.id)
      if (index !== -1) {
        tempDayDelivery[index] = currentDelivery
        this.setDayDelivery(tempDayDelivery)
      }
    } else {
      const orders = delivery.orders.map(x => ({ ...x, new: true }))
      tempDayDelivery.push({ ...delivery, orders })
      this.setDayDelivery(tempDayDelivery)
    }
  },
  async getDayDelivery (menuName: string) {
    const dayDelivery = await deliveryService.getDelivery(formatBackEndDate(this.getDeliveryDate()), menuName)
    this.setDayDelivery(dayDelivery)
  },
  async getDeliveriesDates (menuName: string) {
    this.deliveriesDates = await deliveryService.getAllDeliveriesDates(menuName)
  },
  async refreshDelivery (menuName: string, showLoading: boolean = false) {
    if (showLoading) {
      this.setLoading(true)
    }
    await this.getUserDelivery(menuName)
    await this.getDayDelivery(menuName)
    await this.getDeliveriesDates(menuName)
    this.setLoading(false)
  },
  async payDelivery (delivery: PayDelivery) {
    await deliveryService.payDelivery(delivery)
  },
  async onInitialize () {
    const menuNames = await menusService.getMenuNames()
    if (msalInstance.getActiveAccount()) {
      if (menuNames.length > 0) {
        await this.refreshDelivery(MenuStore.getSelectedMenuName(), true)
      }
    }
  }

})
export const OrderStore = asGlobalStore(createStore())
