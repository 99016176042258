import { Client, type StompSubscription } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import { OrderStore } from '../shared/stores/order-store'
import { getTenantId } from '../shared/tenant/tenant'
import { MenuStore } from '../shared/stores/menu-store'
import { type Delivery, type Order } from '../shared/model'
import { useStore } from '../shared/utils/stores'

class WebSocketService {
  private readonly client: Client
  private readonly subscriptions: StompSubscription[] = []

  constructor () {
    this.client = new Client({
      webSocketFactory: () => new SockJS(`${process.env.REACT_APP_API_URL}/gs-guide-websocket`),
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000
    })

    this.client.onConnect = (frame) => {
      this.handleOrderSubscription()
    }

    this.client.onStompError = (frame) => {
      console.error('Broker reported error: ' + frame.headers.message)
      console.error('Additional details: ' + frame.body)
    }
  }

  connect () {
    this.client.activate()
  }

  disconnect () {
    this.client.deactivate()
  }

  subscribe (destination: string, callback: (message: any) => void) {
    const subscription = this.client.subscribe(destination, callback)
    this.subscriptions.push(subscription)
  }

  handleOrderSubscription () {
    if (this.client.connected) {
      this.subscriptions.forEach(sub => { sub.unsubscribe() })
      this.subscribe(`/topic/orders/${getTenantId()}/${MenuStore.getSelectedMenuName()}`, async (message) => {
        if (message.body === 'Order deleted') {
          await OrderStore.deleteFirstNonPayedDelivery()
        } else {
          const delivery: Delivery = JSON.parse(message.body)
          await OrderStore.updateDelivery(delivery)
        }
      })
    }
  }

  sendMessage (destination: string, body: any) {
    this.client.publish({ destination, body: JSON.stringify(body) })
  }
}

export const webSocketService = new WebSocketService()
