import { type Delivery, type PayDelivery } from '../../../../shared/model'
import React from 'react'
import { OrderList } from './OrderList'
import { withObserver } from '../../../../shared/utils/stores'
import { getFirstOrNonPayedDelivery } from '../../../../shared/utils/delivery'

interface Props {
  deliveries: Delivery[]
  getDeliveries: () => void
  payDelivery: (delivery: PayDelivery) => Promise<void>
  isLoading: boolean
  deliveryDate: Date
  setDeliveryDate: (date: Date) => void
  deliveriesDates: string[]
}

const Component = (props: Props) => {
  const { deliveries, getDeliveries, payDelivery, isLoading, deliveryDate, setDeliveryDate, deliveriesDates } = props

  return (
              <OrderList isLoading={isLoading} orders={getFirstOrNonPayedDelivery(deliveries)} getDeliveries={getDeliveries} payDelivery={payDelivery} deliveryDate={deliveryDate} setDeliveryDate={setDeliveryDate} deliveriesDates={deliveriesDates}/>
  )
}

export const Deliveries = withObserver(Component)
