import Axios, { type AxiosError } from 'axios'
import { AuthStore } from '../stores/auth-store'

import { createStandaloneToast } from '@chakra-ui/react'

const { toast } = createStandaloneToast()

export enum CallType {GET, POST, PUT, DELETE}

export const getApi = () => Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: false
})

export const getSecuredApi = async (bearer: string) => Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'access-control-allow-origin': '*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${bearer}`
  },
  withCredentials: false
})

export const apiCall = async (secured: boolean, url: string, callType: CallType, body?: any) => {
  let api
  let asposeResponse
  if (!secured) {
    api = await getApi()
  } else {
    const bearer = await AuthStore.getToken()
    api = await getSecuredApi(bearer)
  }
  switch (callType) {
    case CallType.GET:
      asposeResponse = await api.get(url, body).catch(function (error: AxiosError) {
        handleErrorResponse(error)
      })
      break
    case CallType.POST:
      asposeResponse = await api.post(url, body).catch(function (error: AxiosError) {
        handleErrorResponse(error)
      })
      break
    case CallType.PUT:
      asposeResponse = await api.put(url, body).catch(function (error: AxiosError) {
        console.log(error.response?.status)
        handleErrorResponse(error)
      })
      break
    case CallType.DELETE:
      asposeResponse = await api.delete(url, body).catch(function (error: AxiosError) {
        handleErrorResponse(error)
      })
      break
    default:
      asposeResponse = await api.get(url, body).catch(function (error: AxiosError) {
        handleErrorResponse(error)
      })
      break
  }

  return asposeResponse?.data ?? null
}

const handleErrorResponse = (error: AxiosError) => {
  // toast({ title: 'Er ging iets fout', status: 'error', duration: 9000, isClosable: true, description: 'Er ging iets fout, kijk in de log.' })
  console.log(error.toJSON())
}
