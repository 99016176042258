import { FoodCard } from './components/FoodCard'
import React, { useEffect, useState } from 'react'
import { OrdersTodayList } from './components/orders-today/OrdersToday'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Grid,
  useColorModeValue,
  useToast
} from '@chakra-ui/react'
import { SideMenu } from '../shared/layout/side-menu/SideMenu'
import { MenuStore } from '../shared/stores/menu-store'
import { withObserver } from '../shared/utils/stores'
import { isMobile } from '../shared/utils/mobileCheck'
import { Header } from '../shared/layout/header/Header'
import { OrderStore } from '../shared/stores/order-store'
import { getFirstOrNonPayedDelivery, hasOpenDelivery } from '../shared/utils/delivery'
import { msalInstance } from '../index'
import { getScrollBar } from '../shared/utils/scrollbar'
import { FoodCardsMockLoading } from './components/FoodCardsMockLoading'
import { OrdersTodayBar } from './components/orders-today/components/OrdersTodayBar'
import { type UserOrder } from '../shared/model'
import { webSocketService } from '../services/websocket-service'
import { useLocation } from 'react-router-dom'

const Component = () => {
  const bg = useColorModeValue('utils.200', 'utils.bg.dark')
  const bgAlert = useColorModeValue('white', '#404346')
  const [showOrdersToday, setShowOrdersToday] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [tempPersonalOrder, setTempPersonalOrder] = useState<UserOrder>()
  const cancelRef = React.useRef<HTMLButtonElement>(null)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const menuName = queryParams.get('name')

  const toast = useToast()

  const closeOrdersToday = () => {
    setShowOrdersToday(false)
  }
  const openOrdersToday = () => {
    setShowOrdersToday(true)
  }

  const openAlert = () => {
    setShowAlert(true)
  }

  const closeAlert = () => {
    setShowAlert(false)
  }

  const postOrder = async () => {
    if (tempPersonalOrder) {
      await store.setPersonalOrder(tempPersonalOrder)
    }
  }

  const store = OrderStore

  useEffect(() => {
    const fetchData = async () => {
      if (menuName && MenuStore.getMenuByName(menuName)) {
        const menu = MenuStore.getMenuByName(menuName)
        if (menu) {
          await MenuStore.setSelectedMenu(menu)
        }
      } else {
        if (MenuStore.menuNameList.length > 0) {
          await MenuStore.setSelectedMenu(MenuStore.menuNameList[0])
        }
      }
    }
    fetchData()
  }, [MenuStore.initialized, menuName])

  useEffect(() => {
    webSocketService.connect()
    return () => {
      webSocketService.disconnect()
    }
  }, [])

  useEffect(() => {
    if (MenuStore.getSelectedMenuName() !== '' && MenuStore.getSelectedMenuName() && MenuStore.newMenuSelected && msalInstance.getActiveAccount()) {
      store.refreshDelivery(MenuStore.getSelectedMenuName())
      MenuStore.refreshFavoriteMenuItems()
      MenuStore.setNewMenuSelected(false)
      webSocketService.handleOrderSubscription()
    }
  }, [MenuStore.selectedMenu])

  return (
        <>
            <Grid h={'100vh'} height={{ base: '100dvh', lg: '100vh' }} bg={bg} overflow={'hidden'}
                  templateColumns={{ base: '', lg: '5rem auto 30vw', '2xl': '5rem auto 30rem' }}
                  templateRows={{ base: '64px auto 64px', lg: 'none' }}>
                {isMobile()
                  ? (
                        <>
                            <Header image={MenuStore.selectedMenu.image} title={MenuStore.getSelectedMenuName()}
                                    orderMode={true} refresh={store.refresh}/>
                        </>)
                  : <SideMenu orderMode={true} refresh={store.refresh}/>
                }
                <Grid p={{ base: 4, '2xl': 8 }} gap={{ base: 4, '2xl': 8 }} gridAutoRows={'max-content'}
                      templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)', '2xl': 'repeat(2, 1fr)' }} w={'100%'}
                      overflowX={'hidden'}
                      overflowY={'auto'} css={getScrollBar()}>
                    {MenuStore.selectedMenu?.items?.length > 0
                      ? MenuStore.selectedMenu.items.slice()
                        .sort((a, b) =>
                          (MenuStore.favoriteMenuItems.find(x => x.id === a.id) ? 2 : a.itemOrder < b.itemOrder ? 1 : 0) < (MenuStore.favoriteMenuItems.find(x => x.id === b.id) ? 2 : b.itemOrder < a.itemOrder ? 1 : 0) ? 1 : -1)
                        .map((m) => (
                                <FoodCard key={m.id} menuItem={m}
                                          favorite={!!MenuStore.favoriteMenuItems?.find(x => x.id === m.id)}
                                          postOrder={async (o) => {
                                            if (store.dayDelivery.length > 0 && !hasOpenDelivery(store.dayDelivery)) {
                                              setTempPersonalOrder(o)
                                              openAlert()
                                            } else {
                                              await store.setPersonalOrder(o)
                                            }
                                          }} deliveryDate={store.getDeliveryDate()}/>
                        ))
                      : <FoodCardsMockLoading amount={16}/>
                    }
                </Grid>
                {
                    isMobile()
                      ? <Drawer isOpen={showOrdersToday} placement='right' onClose={closeOrdersToday}>
                            <DrawerOverlay/>
                            <DrawerContent bg={bg} w={'100vw !important'} maxW={'22rem'}>
                                <DrawerCloseButton/>
                                <DrawerBody pt={0} px={0} pb={0}>
                                    <OrdersTodayList
                                        deliveryDate={store.deliveryDate}
                                        deliveriesDates={store.deliveriesDates}
                                        setDeliveryDate={(deliveryDate: Date) => {
                                          store.setDeliveryDate(deliveryDate)
                                        }}
                                        isLoading={store.isLoading}
                                        deletePersonalOrder={async (deliveryId, orderId) => {
                                          await store.removePersonalOrder(deliveryId, orderId)
                                        }}
                                        getDeliveries={async () => {
                                          await store.refreshDelivery(MenuStore.getSelectedMenuName(), true)
                                        }}
                                        orders={store.dayDelivery} personalOrders={store.userDelivery}
                                        payDelivery={async (d) => {
                                          await store.payDelivery(d)
                                          toast({
                                            title: `${d.paymentURL ? 'Betaling gelukt' : 'Bestelling gelukt'}`,
                                            description: `${d.paymentURL ? 'Mail is succesvol verstuurd' : 'Gratis bestelling is verwerkt'}`,
                                            status: 'success',
                                            duration: 9000,
                                            isClosable: true
                                          })
                                        }}/>
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                      : <OrdersTodayList
                            deliveryDate={store.deliveryDate}
                            setDeliveryDate={(deliveryDate: Date) => {
                              store.setDeliveryDate(deliveryDate)
                            }}
                            deliveriesDates={store.deliveriesDates}
                            isLoading={store.isLoading}
                            deletePersonalOrder={async (deliveryId, orderId) => {
                              await store.removePersonalOrder(deliveryId, orderId)
                            }}
                            getDeliveries={async () => {
                              await store.refreshDelivery(MenuStore.getSelectedMenuName(), true)
                            }}
                            orders={store.dayDelivery} personalOrders={store.userDelivery}
                            payDelivery={async (d) => {
                              await store.payDelivery(d)
                              toast({
                                title: `${d.paymentURL ? 'Betaling gelukt' : 'Bestelling gelukt'}`,
                                description: `${d.paymentURL ? 'Mail is succesvol verstuurd' : 'Gratis bestelling is verwerkt'}`,
                                status: 'success',
                                duration: 9000,
                                isClosable: true
                              })
                            }}/>
                }
                {isMobile() &&
                    (
                        <>
                            <OrdersTodayBar showOrdersToday={openOrdersToday}
                                            delivery={getFirstOrNonPayedDelivery(store.dayDelivery)}/>
                        </>)
                }
            </Grid>
            <AlertDialog
                isOpen={showAlert}
                isCentered
                onClose={closeAlert} leastDestructiveRef={cancelRef}>
                <AlertDialogOverlay>
                    <AlertDialogContent bg={bgAlert}>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Nieuwe bestelling starten
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            De bestelling vandaag is al besteld, weet je zeker dat je een nieuwe bestelling wilt
                            starten?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={closeAlert}>
                                Annuleren
                            </Button>
                            <Button variant={'utilsButtonPrimary'} onClick={() => {
                              postOrder()
                              closeAlert()
                            }} ml={3}>
                                Bestelling starten
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
  )
}

export const MenuScreen = withObserver(Component)
