import { type Menu, type MenuItem, type MenuNameDTO } from '../model'
import { menusService } from '../../services/menu-service'
import { asGlobalStore } from '../utils/stores'

const orderMenu = (menu: Menu) => {
  menu.items.sort((a, b) => a.itemOrder > b.itemOrder ? 1 : -1)
  return menu
}

const createStore = () => ({
  menuNameList: [] as MenuNameDTO[],
  selectedMenu: {} as Menu,
  favoriteMenuItems: [] as MenuItem[],
  newMenuSelected: false,
  isLoading: false,
  initialized: false,
  async setSelectedMenu (menu: MenuNameDTO, initialize: boolean = false) {
    if (!initialize) {
      this.newMenuSelected = true
    }
    this.selectedMenu = { name: menu.name, image: menu.image, tenants: [], options: [], items: [] }
    const selectedMenu = await menusService.getMenu(menu.name)

    this.selectedMenu = orderMenu(selectedMenu)
  },
  menus: [] as Menu[],
  async getAllMenus () {
    const menus = await menusService.getAllMenus()
    const sortedMenuLists: Menu[] = []
    menus.forEach(x => {
      sortedMenuLists.push(orderMenu(x))
    })
    this.setMenus(sortedMenuLists)
  },
  async updateFavoriteMenuItems (favorite: boolean, menuItem: MenuItem) {
    if (favorite) {
      await menusService.addFavoriteMenuItem(menuItem.id)
    } else {
      await menusService.deleteFavoriteMenuItem(menuItem.id)
    }
    await this.refreshFavoriteMenuItems()
  },
  async refreshFavoriteMenuItems (menuName?: string) {
    const favItems = await menusService.getFavoriteMenuItems(menuName ?? this.getSelectedMenuName())
    this.setFavoriteMenuItems(favItems)
  },
  setFavoriteMenuItems (favItems: MenuItem[]) {
    this.favoriteMenuItems = favItems
  },
  setMenus (menus: Menu[]) {
    this.menus = menus
  },
  setMenuNameList (menuNameList: MenuNameDTO[]) {
    this.menuNameList = menuNameList
  },
  setNewMenuSelected (newMenuSelected: boolean) {
    this.newMenuSelected = newMenuSelected
  },
  getMenuByName (name: string) {
    return this.menuNameList.find(m => m.name === name)
  },
  getSelectedMenuName () {
    return this.selectedMenu.name
  },
  getMenus () {
    return this.menus
  },
  async updateMenu (menu: Menu) {
    await menusService.updateMenu(menu)
    await this.refreshMenu(menu.name)
  },
  async refreshMenu (name: string) {
    await this.setSelectedMenu(await menusService.getMenu(name))
    this.setMenuNameList(await menusService.getMenuNames())
    if (this.menus.length === 0) {
      await this.setSelectedMenu({} as Menu)
    }
  },
  async refreshMenuList () {
    this.setMenuNameList(await menusService.getMenuNames())
  },
  async deleteMenu (name: string) {
    this.isLoading = true
    await menusService.deleteMenu(name)
    await this.refreshMenuList()
    this.isLoading = false
  },
  async addMenu (menu: Menu) {
    await menusService.addMenu(menu)
    // await this.refreshMenus()
  },
  async onInitialize () {
    this.setMenuNameList(await menusService.getMenuNames())
    this.initialized = true
  }
})

export const MenuStore = asGlobalStore(createStore())
